import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './styles/Auth.module.css';
import InputHOH from '../components/InputHOH';
import ButtonHOH from '../components/ButtonHOH';
import Question from '../components/Question';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSocket } from '../sockets';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .max(100, 'Username contains more than 100 characters')
    .required('Obligatory field'),
  username: Yup.string()
    .matches(/^[a-zA-Z0-9._]*$/, 'Use only A-Z, a-z and numbers')
    .max(30, 'Username contains more than 30 characters')
    .required('Obligatory field'),
  password: Yup.string()
    .min(8, 'The password must be at least 8 characters long')
    .required('Obligatory field'),
});

function SignUp({ playSound }) {

  const navigate = useNavigate();
  const location = useLocation();

  const { socket, signup, loading, setLoading, error } = useSocket();

  const handleSubmit = async (values, setErrors) => {
    setLoading(true);
    await signup(values, navigate, setErrors);
  }

  useEffect(() => {
    if (socket) {
      navigate('/game', { replace: true }) /** navigate('/crypto')   ***********/
    }
  }, [socket])

  const referral_code = location.search.split("=")[1]
  if (referral_code)
    localStorage.setItem("referral_code", referral_code)


  return (
    <div className={styles.main}>
      <div className={styles.bgImage}>
        <div className={styles.auth}>
          <div className={styles.header}>
            <img src="/logo.svg" alt="log" />
          </div>
          <div className={styles.form}>
            <h1>Create an account!</h1>
            <Formik
              initialValues={{ email: '', username: '', password: '', inviteCode: localStorage.getItem("referral_code") }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, setErrors}) => {
                handleSubmit(values, setErrors)
              }}
            >
              {({ isSubmitting, touched, errors }) => {

                return (
                  <Form>

                    <InputHOH label="E-mail" name="email" type="text" errors={errors} style={{ marginBottom: "2vh" }} />

                    <InputHOH label="Username" name="username" type="text" errors={errors} style={{ marginBottom: "2vh" }} />
                    {/* {errors.username && <div>{errors.username}</div>} */}
                    <InputHOH label="Password" name="password" type="password" errors={errors} style={{ marginBottom: "2vh" }} />
                    {/* {errors.password && <div>{errors.password}</div>} */}
                    <InputHOH value={localStorage.getItem("referral_code")} label="Invite code (optional)" name="inviteCode" type="text" errors={errors} style={{ marginBottom: "2vh" }} />
                    <ButtonHOH {...{ playSound }} text="Sign Up" type="submit" style={{ marginTop: 8 }} blocked={loading} />
                  </Form>
                )
              }
              }
            </Formik>
            <Question text="Already have an account?" />
            <ButtonHOH  {...{ playSound }} text="Log In" reverse={true} onClick={() => navigate('/', { replace: true })} blocked={loading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
