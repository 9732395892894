import styles from './styles/MenuBar.module.css';
import { useSocket } from '../sockets';

function MenuBar( {playSound}) {

  const { setModal } = useSocket();

  const handleClick = (value) =>{
    setModal(value);
    playSound();
  }

  return (
    <div className={styles.menubar}>
      <div onClick={() => handleClick("tasks")}>
        <img src={require("../screens/images/book.svg").default} alt="" />
        <span>Tasks</span>
      </div>
      <div onClick={() => handleClick("boosts")}>
        <img src={require("./images/shield-up-line-duotone.svg").default} alt="" />
        <span>Boost</span>
      </div>
      <div onClick={() => handleClick("leaders")}>
        <img src={require("./images/cup-star-line-duotone.svg").default} alt="" />
        <span>Leaderboard</span>
      </div>
    </div>
  );
}

export default MenuBar;
