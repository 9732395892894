import styles from './styles/ChangeUsername.module.css';
import InputHOH from '../components/InputHOH';
import ButtonHOH from '../components/ButtonHOH';
import HeaderHOH from '../components/HeaderHOH';
import Question from '../components/Question';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useSocket } from '../sockets';
import Verify from './Verify';


let validationSchema = null


function RecoveryPassword({ playSound }) {

  const navigate = useNavigate();

  const { loading, setLoading, emailVerify, setNewPassword, verifyToken } = useSocket();

  if(!verifyToken)
  validationSchema = Yup.object().shape({
    current_email: Yup.string()
      .email('Некорректный email')
      .max(100, 'Имя пользователя содержит больше 100 символов')
      .required('Обязательное поле'),
  });
  else
  validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(8, 'Пароль должен быть не короче 8 символов')
      .max(100, 'Имя пользователя содержит больше 100 символов'),
    new_password_repeat: Yup.string()
      .min(8, 'Пароль должен быть не короче 8 символов')
      .max(100, 'Имя пользователя содержит больше 100 символов')
      .test('repeat-password-check', 'Пароли должны совпадать', function() {
        if(this.from[0].value.new_password === this.from[0].value.new_password_repeat)
          return true
        return false
      }),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    verifyToken ? 
      setNewPassword({ new_password: values.new_password, verify_token: verifyToken }, navigate) 
      : emailVerify({email:values.current_email}, navigate)
  }


  return (
    <>
      <HeaderHOH text="Recovery Password" />
      <div className={styles.main}>
        {!verifyToken ?
          <h1>Enter your E-mail</h1>
          :
          <h1>New Password</h1>}
        <Formik
          initialValues={{ current_email: '', new_password: '', new_password_repeat: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values }) => (
            <Form>
              {!verifyToken ?
                <>
                  <InputHOH label="Email" name="current_email" type="email" errors={errors} style={{ marginBottom: 8 }} />
                  <ButtonHOH text="Continue" type="submit" style={{marginTop: 8}} blocked={!values.current_email.length > 0 || errors.current_email || loading}/>
                </>
                :
                <>
                  <InputHOH label="New password" name="new_password" type="password" errors={errors} style={{ marginBottom: 8 }} />
                  <InputHOH label="Repeat new password" name="new_password_repeat" type="password" errors={errors} style={{ marginBottom: 8 }} />
                  <ButtonHOH {...{ playSound }} text="Change password" type="submit" style={{ marginTop: 8 }} blocked={!values.new_password.length > 0 || !values.new_password_repeat.length > 0 || errors.new_password || errors.new_password_repeat || loading} />
                </>}

            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default RecoveryPassword;
