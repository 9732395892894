import styles from './styles/LoadingCircle.module.css';


function loadingCircle() {
    return (
        <div className={styles.loadingCircle}>
            <img src={require("./images/image_circle_loader.png")} />
        </div>
    )
}

export default loadingCircle;