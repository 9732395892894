import { useNavigate } from "react-router-dom";
import { useSocket } from '../sockets';
import Modal from '../components/Modal';

import styles from './styles/ShareWindow.module.css';

function ShareWindow ({playSound}) {

  const navigate = useNavigate();
  const { account } = useSocket();

  const handleModaleClick = () => {
    playSound();
    navigate(-1);
  }

  const handleCopy = async (text, title) => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error('Unable to copy text:', error);
      alert('Failed to copy text.');
    }
  };

  const handleShare = (socialMedia) => {
    let shareUrl;
    const shareURL = `https://app.hoh.game/signup?r=${account?.referal_code}`
    switch (socialMedia) {
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareURL)}`;
        break;
      case 'vk':
        shareUrl = `https://vk.com/share.php?url=${encodeURIComponent(shareURL)}`;
        break;
      case 'discord':
        shareUrl = `https://discord.com/channels/new?utm_source=share&utm_medium=button&utm_campaign=URLShare&url=${encodeURIComponent(shareURL)}`;
        break;
      case 'instagram':
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(shareURL)}`;
        break;
      case 'x':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareURL)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareURL)}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  };

    return(
        <Modal onClose={() => handleModaleClick()}>
            <div className={styles.share}>
              <div className={styles.shareTitle}>
                <img src={require("../components/images/share.svg").default} alt='share' />
                <span>Share</span>
              </div>
              <div className={styles.shareList}>
                <div className={styles.socialNetwork} id="telegram" onClick={() => handleShare("telegram")}>
                  <img src={require("../components/images/telegram.svg").default} />
                  <span>Telegram</span>
                </div>
                <div className={styles.socialNetwork} id="vk" onClick={() => handleShare("vk")}>
                  <img src={require("../components/images/vk.svg").default} />
                  <span>Вконтакте</span>
                </div>
                <div className={styles.socialNetwork} id="whatsapp" onClick={() => handleShare("whatsapp")}>
                  <img src={require("../components/images/whatsapp.svg").default} />
                  <span>Whatsapp</span>
                </div>
                <div className={styles.socialNetwork} id="x" onClick={() => handleShare("x")}>
                  <img src={require("../components/images/x.svg").default} />
                  <span>X</span>
                </div>
              </div>
              <div className={styles.input}>
                <div>
                  <span>Invite code</span>
                  <span>{`https://app.hoh.game/signup?r=${account?.referal_code}`}</span>{/* <span>{account?.referal_code.toString().slice(0, 3)} {account?.referal_code.toString().slice(3, 6)}</span> */}
                </div>
                <div onClick={() => handleCopy(account.referal_code)}>
                  <img src={require("./images/copy-line-duotone.svg").default} alt="" />
                </div>
              </div>
            </div>
          </Modal>
    )
}

export default ShareWindow;