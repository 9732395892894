import { YMInitializer } from "react-yandex-metrika"

const YandexMetrika = ({ children }) => {
    return (
        <>
            <YMInitializer
                accounts={[97073209]}
                options={{ webvisor: true }}
                version="2"
            />
            {children}
        </>
    )
}

export default YandexMetrika;
