import styles from './styles/Promo.module.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderHOH from '../components/HeaderHOH';
import ButtonHOH from '../components/ButtonHOH';
import { useSocket } from '../sockets';
import { getBalance } from '../crypto';

function Promo({ playSound }) {

  const navigate = useNavigate();

  const { account, socket, setAccount, logout, accessToken, refreshToken, referals, getReferals, setModal, modal } = useSocket();


  useEffect(() => {
    getReferals();
  }, [accessToken, refreshToken])

  useEffect(() => {
    if (!accessToken && !refreshToken) {
      logout(navigate);
    }
  }, [accessToken, refreshToken])

  const handleCopy = async (text, title) => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error('Unable to copy text:', error);
      alert('Failed to copy text.');
    }
  };

  return (
    <>
      <HeaderHOH text="Referral program" />
      <div className={styles.main}>
        <div className={styles.block}>
          <div className={styles.title}>
            <div>Your promo</div>
          </div>
          <div className={styles.text}>Share the referral link with your friends and get energy as a reward for each friend who has played 5 games.</div>
          <div className={styles.input} onClick={() => handleCopy(account.referal_code)}>
            <div>
              <span>Invite code</span>
              <span className={styles.code}>{account?.referal_code}</span>{/* <span>{account?.referal_code.toString().slice(0, 3)} {account?.referal_code.toString().slice(3, 6)}</span> */}
            </div>
            <div>
              <img src={require("./images/copy-line-duotone.svg").default} alt="" />
            </div>
          </div>
          <ButtonHOH {...{ playSound }} text="Share" onClick={() => navigate("/share")} />
          {/* <div className={styles.textCenter}>You will receive <span>0.1% of your referral's earnings</span>.</div> */}
        </div>
        <div className={styles.block}>
          <div className={styles.title}>
            <div>Referrals</div>
            <div>{referals.length}</div>
          </div>
          <div className={styles.items}>
            <div className={`${styles.item} ${styles.headers}`}>
              <span className={styles.notpaid}>Nick Name</span>
              <span className={styles.notpaid}>Games</span>
              <span className={styles.notpaid}>Reward</span>
            </div>
            {referals?.map((referal) => {
              return (
                <div className={styles.item}>
                  <div>{referal.username}</div>
                  <div className={referal?.paid ? `` : `${styles.notpaid}`}>{referal.energySpent}</div>
                  <div><img src={referal?.paid? require(`../components/images/energy-active.svg`).default : require(`../components/images/energy-black.svg`).default} /><span className={referal?.paid ? `` : `${styles.notpaid}`}>+15</span></div>
                </div>)
            })
            }
          </div>
          <ButtonHOH {...{playSound}} text="Load more" reverse={true} onClick={()=>{}}/>
        </div>
      </div>
    </>
  );
}

export default Promo;
