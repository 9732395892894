import styles from './styles/Game.module.css';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import MenuBar from '../components/MenuBar';
import Header from '../components/Header';
import EnergyBar from '../components/EnergyBar';
import Button from '../components/Button';
import ButtonHOH from '../components/ButtonHOH';
import Modal from '../components/Modal';
import LoadingCircle from '../components/LoadingCircle';

import { useSocket } from '../sockets';

import CardSound from "./audio/open_card.mp3"


function Game({ playSound }) {

  const navigate = useNavigate();

  const openCardSound = useMemo(() => new Audio(CardSound), []);

  const { modal,
    setModal,
    logout,
    accessToken,
    refreshToken,
    energy,
    setEnergy,
    generationEnergy,
    setGenerationEneregy,
    boostAutomaticExtract,
    setBoostAutomaticExtract,
    sendMessage,
    gameResult,
    setGameResult,
    account,
    setAccount,
    jackpot,
    boosts,
    settings,
    leaders,
    partners,
    tasks } = useSocket();

  const [gamePhase, setGamePhase] = useState(null);
  const [isFlipped, setIsFlipped] = useState([false, false, false]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedBoost, setSelectedBoost] = useState(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState();
  const [timeLeftEnergy, setTimeLeftEnergy] = useState('00:00');
  const [timeLeftExtractBoost, setTimeLeftExtractBoost] = useState('00:00:00');

  useEffect(() => {
    if (!accessToken && !refreshToken) {
      logout(navigate);
    }
  }, [accessToken, refreshToken])

  useEffect(() => {
    const fetchData = () => {
      try {
        sendMessage(JSON.stringify(["energy", "get"]));
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = (index) => {
    if (gamePhase === "started") {
      if (energy?.value > 0 || energy?.task_value > 0) {
        sendMessage(JSON.stringify(["game", energy._id, index]));
        setIsFlipped([true, true, true]);
        setSelectedCardIndex(index);
        setGamePhase("ended");
        if (energy?.value > 0) {
          setEnergy(prevEnergy => ({ ...prevEnergy, value: prevEnergy.value - 1 }));
        } else {
          setEnergy(prevEnergy => ({ ...prevEnergy, task_value: prevEnergy.task_value - 1 }));
        }

        openCardSound.load();
        openCardSound.play();
      }
    }
  };

  const handleModaleClick = (modaleType, boost) => {
    setModal(modaleType);
    if (boost !== undefined) setSelectedBoost(null);

    playSound();
  }

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error('Unable to copy text:', error);
      alert('Failed to copy text.');
    }
  };

  const handleBoost = (i) => {
    if (energy && settings) {
      if (i === 1) {
        if (energy.minutes > settings["min_energy_minutes"]) {
          sendMessage(JSON.stringify(["boost", "use", energy._id, selectedBoost._id]));
        }
      } else if (i === 2) {
        if (energy.limit < settings["max_energy_limit"]) {
          sendMessage(JSON.stringify(["boost", "use", energy._id, selectedBoost._id]));
        }
      }
      else if (i === 3) {
        if (!boostAutomaticExtract) {
          sendMessage(JSON.stringify(["boost", "use", energy._id, selectedBoost._id]));
        }
      }
    }
  }

  const restartGame = () => {
    setIsFlipped([false, false, false]);
    setSelectedCardIndex(null);
    setGamePhase("started");
    setGameResult([1, 1, 1]);

    playSound();
  }

  const addGeneration = () => {
    playSound()
    if (energy) {
      sendMessage(JSON.stringify(["generation", "add", energy._id]));
    }
  }

  useEffect(() => {
    if (!gamePhase) {
      setIsFlipped([false, false, false]);
      setSelectedCardIndex(null);
    }
  }, [gamePhase])

  useEffect(() => {
    const calculateTimeLeftEnergy = () => {
      const now = new Date();
      const nowUtc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      const targetDate = new Date(generationEnergy.end_at.replace(' ', 'T'));
      const diff = targetDate - nowUtc;

      if (diff < 0) {
        setTimeLeftEnergy('00:00');
        setGenerationEneregy(null);
        setEnergy(prevEnergy => ({ ...prevEnergy, value: prevEnergy.value + 1 }));
        if (boostAutomaticExtract) {
          addGeneration();
        }
        return;
      }

      const minutes = Math.floor(diff / 1000 / 60);
      const seconds = Math.floor((diff / 1000) % 60);

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

      setTimeLeftEnergy(`${formattedMinutes}:${formattedSeconds}`);
    };
    if (generationEnergy) {
      calculateTimeLeftEnergy();
      const intervalId = setInterval(calculateTimeLeftEnergy, 1000);
      return () => {
        clearInterval(intervalId)
      };
    }
  }, [generationEnergy]);

  useEffect(() => {
    const calculateTimeLeftExtractBoost = () => {
      const now = new Date();
      const nowUtc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      const targetDate = new Date(boostAutomaticExtract.end_at.replace(' ', 'T'));
      const diff = targetDate - nowUtc;

      if (diff < 0) {
        setTimeLeftExtractBoost('00:00:00');
        setBoostAutomaticExtract(null);
        return;
      }

      const hours = Math.floor((diff / 1000) / 3600)
      const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
      const seconds = Math.floor((diff / 1000) % 60);

      const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

      setTimeLeftExtractBoost(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    };

    if (boostAutomaticExtract) {
      calculateTimeLeftExtractBoost();
      const intervalId = setInterval(calculateTimeLeftExtractBoost, 1000);
      return () => {
        clearInterval(intervalId)
      };
    }

  }, [boostAutomaticExtract]);

  useEffect(() => {
    openCardSound.addEventListener('ended', () => openCardSound.pause());
    return () => {
      openCardSound.removeEventListener('ended', () => openCardSound.pause());
    };
  }, []);


  return (
    <>
      <div className={styles.main} style={{ backgroundImage: 'url("bg.png")' }}>
        <div className={gamePhase ? styles.bgVideoHidden : (generationEnergy ? styles.bgVideo : styles.bgVideoHidden)}>
          <video preload="auto" muted playsInline autoPlay loop>
            <source src="bg-generation.mp4" type="video/mp4" />
          </video>
        </div>
        <div className={gamePhase ? styles.bgVideo : styles.bgVideoHidden}>
          <div className={styles.greenLight}> <img src={require("./images/green_light.svg").default} /></div>
          <div className={styles.lightEffect}> <img src={require("./images/light-effect.png")} /> </div>
        </div>
        <Header {...{ playSound }} />
        {!gamePhase &&
          <>
            {!generationEnergy && (
              account ?
                <div className={styles.hamster}>
                  <img src={account?.avatar ? account.avatar : require("./images/hamster.png").default} />
                </div>
                : <LoadingCircle />
            )}
            <EnergyBar />

            <div className={styles.buttons}>
              {energy?.limit > energy?.value &&
                <Button text={generationEnergy ? `${timeLeftEnergy} +1` : "START +1"} onClick={() => !generationEnergy && addGeneration()} icon={generationEnergy ? require("../components/images/energy-active.svg").default : require("../components/images/energy-white.svg").default} style={{
                  color: generationEnergy ? "#9DFF12" : "#fff",
                  background: "rgba(0, 0, 0, 0.3)",
                  borderWidth: 2,
                  borderColor: "rgba(255, 255, 255, 0.3)"
                }} />}
              {(energy?.value > 0 || energy?.task_value > 0) &&
                <Button text="PLAY" onClick={() => setGamePhase("started")} style={null} />}
            </div>
          </>}
        {gamePhase &&
          <>
            <div className={styles.center}>
              {energy?.value > 0 || energy?.task_value > 0 ?
                <div className={styles.whiteText}>Choose 1 of 3 cards<br />to receive a reward</div>
                : <div className={styles.whiteText}>Replenish your cellular energy<br />to continue playing.</div>
              }
              <div className={styles.cards}>

                {isFlipped.map((flipped, index) => {

                  const cardInfo = gameResult[index]
                  return (
                    <div className={`${styles.card} ${flipped && styles.flipped} ${selectedCardIndex === index ? styles.selected : styles.not_selected}`} onClick={() => handleClick(index)} key={index} >
                      <div className={styles.cardFront}>
                        <img src={`./cards/${cardInfo.sprite}`} alt="" />
                        {cardInfo.type === "coins" && !cardInfo.is_jackpot &&
                          <span className={styles.coins}>{cardInfo.value}</span>}
                        {cardInfo.is_jackpot &&
                          <div className={styles.jackpot}>
                            <span className={`${styles.jackpotCoins} ${styles.percents}`}>{cardInfo.percent}%</span>
                            <span className={styles.jackpotCoins}>{cardInfo.value}</span>
                          </div>}
                      </div>
                      <div className={styles.cardBack}>
                        <img src={require("./images/card-back.png")} alt="" />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className={styles.energyLimit}>
                <b>{(energy?.value || 0) + (energy?.task_value || 0)}</b> <img src={require("../components/images/energy-active.svg").default} alt="" /> energy left
              </div>
            </div>
            <div className={styles.buttons} style={{ marginTop: "auto" }}>
              <Button text="BACK" onClick={() => {
                setGamePhase(null);
                playSound();
              }}
                style={{
                  color: "#fff",
                  background: "rgba(0, 0, 0, 0.3)",
                  borderWidth: 2,
                  borderColor: "rgba(255, 255, 255, 0.3)"
                }} />
              {(gamePhase === "ended" && (energy?.value > 0 || energy?.task_value > 0)) &&
                <Button text="PLAY MORE -1" icon={require("../components/images/energy-black.svg").default} onClick={restartGame} />}
            </div>
          </>}
        {(modal === 'yt' && jackpot) &&
          <Modal onClose={() => handleModaleClick(null)}>
            <div className={styles.ytImage}>
              <img src={require("./images/image.png")} alt="" />
              <div>LIVE</div>
            </div>
            <div className={styles.ytJackpotSum}>
              <img src={require("../components/images/HAMC2.svg").default} alt="" />
              <span>{jackpot.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
            </div>
            <div className={styles.ytTitle}>
              JACKPOT
            </div>
            <div className={styles.ytDescription}>
              The jackpot pool is mined by Hamsters running in a wheel. The live broadcast is available on our YouTube channel.
            </div>
            {/* <div className={styles.ytBaff}>
              +1  ячейка энергии для игры
            </div> */}
            <div className={styles.ytButton}>
              <Link to={jackpot.link} target="_blank">
                <Button text="Go to YouTube stream" style={{ fontWeight: "500" }} />
              </Link>
            </div>
          </Modal>}
        {modal === 'boosts' &&
          <Modal onClose={() => handleModaleClick(null)}>
            <div className={styles.boosts}>
              <div className={styles.boostsTitle}>
                <img src={require("../components/images/shield-up-line-duotone.svg").default} alt="" />
                <span>Boost</span>
              </div>
              <div className={styles.boostsList}>
                {boosts?.map((boost) => (
                  <div className={styles.boostsItem} onClick={() => {
                    setModal("boost");
                    setSelectedBoost(boost);
                    playSound()
                  }} key={boost._id}>
                    <div className={styles.boostsIcon}>
                      {boost.name === "minutes:-1" && <img src={require("./images/material-symbols_speed.svg").default} alt="" />}
                      {boost.name === "limit:+1" && <img src={require("../components/images/energy-active.svg").default} alt="" />}
                      {boost.name === "automatic_extract" && <img src={require("./images/fluent-emoji-high-contrast_robot.svg").default} alt="" />}
                    </div>
                    <div className={styles.boostsInformation}>
                      <div className={styles.boostsItemTitle}>
                        {boost.name === "minutes:-1" && "Hamster speed"}
                        {boost.name === "limit:+1" && "Energy limit"}
                        {boost.name === "automatic_extract" && "Automatic extract"}
                      </div>
                      <div className={styles.boostsItemDescription}>
                        {boost.name === "minutes:-1" && "-30 seconds to extract energy"}
                        {boost.name === "limit:+1" && "+1 energy cell for game"}
                        {boost.name === "automatic_extract" && ""}
                      </div>
                      {(boost.name === "minutes:-1" && energy?.minutes > settings["min_energy_minutes"]) &&
                        <div className={styles.boostsItemPrice}>
                          <img src={require("../components/images/HAMC.svg").default} alt="" />
                          <span>{boost.price * (2 ** (2 * (settings["max_energy_minutes"] - (energy?.minutes || settings["max_energy_minutes"]))))}</span>
                        </div>}
                      {(boost.name === "limit:+1" && energy?.limit < settings["max_energy_limit"]) &&
                        <div className={styles.boostsItemPrice}>
                          <img src={require("../components/images/HAMC.svg").default} alt="" />
                          <span>{boost.price * (2 ** ((energy?.limit || settings["min_energy_limit"]) - settings["min_energy_limit"]))}</span>
                        </div>}
                      {(boost.name === "automatic_extract") &&
                        <div className={styles.boostsItemPrice}>
                          <img src={require("../components/images/HAMC.svg").default} alt="" />
                          <span>{boost.price}</span>
                        </div>}
                    </div>
                    <div className={styles.boostsArrow}>
                      <img src={require("./images/alt-arrow-right-line-duotone.svg").default} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>}
        {modal === 'boost' &&
          <Modal onClose={() => handleModaleClick("boosts", null)}>
            <div className={styles.boostIcon}>
              {selectedBoost.name === "minutes:-1" && <img src={require("./images/material-symbols_speed.svg").default} alt="" />}
              {selectedBoost.name === "limit:+1" && <img src={require("../components/images/energy-active.svg").default} alt="" />}
              {selectedBoost.name === "automatic_extract" && <img src={require("./images/fluent-emoji-high-contrast_robot.svg").default} alt="" />}
            </div>
            <div className={styles.boostTitle}>
              {selectedBoost.name === "minutes:-1" && "Hamster speed"}
              {selectedBoost.name === "limit:+1" && "Energy limit"}
              {selectedBoost.name === "automatic_extract" && "Automatic extract"}
            </div>
            <div className={styles.boostDescription}>
              {selectedBoost.name === "minutes:-1" && "Reduce the number of minutes for energy production"}
              {selectedBoost.name === "limit:+1" && "Maximize your available energy"}
              {selectedBoost.name === "automatic_extract" && "Automatic filling of empty cells"}
            </div>
            <div className={styles.boostBaff}>
              {selectedBoost.name === "minutes:-1" && "-30 seconds to extract energy"}
              {selectedBoost.name === "limit:+1" && "+1 energy cell for game"}
              {selectedBoost.name === "automatic_extract" && "Automatic filling of all available cells with energy within 24 hours"}
            </div>
            {selectedBoost.name === "minutes:-1" && energy?.minutes > settings["min_energy_minutes"] &&
              <div className={styles.boostPriceLevel}>
                <img src={require("../components/images/HAMC.svg").default} alt="" />
                <b>{selectedBoost.price * (2 ** (2 * (settings["max_energy_minutes"] - (energy?.minutes || settings["max_energy_minutes"]))))}</b>
                <span>/ Level {1 + 2 * (settings["max_energy_minutes"] - (energy?.minutes || settings["max_energy_minutes"]))}</span>
              </div>}
            {selectedBoost.name === "minutes:-1" && energy?.minutes <= settings["min_energy_minutes"] &&
              <div className={styles.boostPriceLevel}>
                <span>Level {2 * (settings["max_energy_minutes"] - (energy?.minutes || settings["max_energy_minutes"]))}</span>
              </div>}
            {selectedBoost.name === "minutes:-1" && energy?.minutes > settings["min_energy_minutes"] &&
              <div className={styles.boostButton}>
                <Button text="GET" onClick={() => handleBoost(1)} style={account?.game_balance >= selectedBoost.price * (2 ** (2 * (settings["max_energy_minutes"] - (energy?.minutes || settings["max_energy_minutes"])))) ? { fontWeight: "500" } : { fontWeight: "500", color: "4F4F4F", backgroundColor: "#202020", border: 0 }} />
              </div>}
            {selectedBoost.name === "limit:+1" && energy?.limit < settings["max_energy_limit"] &&
              <div className={styles.boostPriceLevel}>
                <img src={require("../components/images/HAMC.svg").default} alt="" />
                <b>{selectedBoost.price * (2 ** ((energy?.limit || settings["min_energy_limit"]) - settings["min_energy_limit"]))}</b>
                <span>/ Level {1 + ((energy?.limit || settings["min_energy_limit"]) - settings["min_energy_limit"])}</span>
              </div>}
            {selectedBoost.name === "limit:+1" && energy?.limit >= settings["max_energy_limit"] &&
              <div className={styles.boostPriceLevel}>
                <span>Level {(energy?.limit || settings["min_energy_limit"]) - settings["min_energy_limit"]}</span>
              </div>}
            {selectedBoost.name === "limit:+1" && energy?.limit < settings["max_energy_limit"] &&
              <div className={styles.boostButton}>
                <Button text="GET" onClick={() => handleBoost(2)} style={account?.game_balance >= selectedBoost.price * (2 ** ((energy?.limit || settings["min_energy_limit"]) - settings["min_energy_limit"])) ? { fontWeight: "500" } : { fontWeight: "500", color: "4F4F4F", backgroundColor: "#202020", border: 0 }} />
              </div>}
            {selectedBoost.name === "automatic_extract" &&
              <div className={styles.boostPriceLevel}>
                <img src={require("../components/images/HAMC.svg").default} alt="" />
                <b>{selectedBoost.price}</b>
                <span>/ 24 hours</span>
              </div>}
            {selectedBoost.name === "automatic_extract" &&
              <div className={styles.boostButton}>
                <Button text={boostAutomaticExtract ? timeLeftExtractBoost : "GET"} onClick={() => { !boostAutomaticExtract && handleBoost(3) }} style={!boostAutomaticExtract && account?.game_balance >= selectedBoost.price ? { fontWeight: "500" } : { fontWeight: "500", color: "4F4F4F", backgroundColor: "#202020", border: 0 }} />
              </div>}

          </Modal>}
        {modal === 'tasks' &&
          <Modal onClose={() => handleModaleClick(null)}>
            <div className={styles.boosts}>
              <div className={styles.boostsTitle}>
                <img src={require("./images/book.svg").default} alt="" />
                <span>Tasks</span>
              </div>
              <div className={`${styles.boostsList}`}>
                <div className={styles.boostsItem} onClick={() => {
                  setModal("task");
                }}>
                  <div className={styles.tasksIcon}>
                    <img src={require("../components/images/referral.svg").default} alt="" />
                  </div>
                  <div className={styles.boostsInformation}>
                    <div className={styles.boostsItemTitle}>Invite a friend</div>
                    <div className={styles.boostsItemPrice}>
                      <img src={require("../components/images/energy-active.svg").default} alt="" />
                      <span>+{15}</span>
                    </div>
                  </div>
                  <div className={styles.boostsArrow}>
                    <img src={require("./images/alt-arrow-right-line-duotone.svg").default} alt="" />
                  </div>
                </div>
                {/* {partners?.map((partner) => (
                  <div className={styles.boostsItem} onClick={() => {
                    setSelectedPartnerId(partner._id);
                    setModal("task");
                  }}>
                    <div className={styles.tasksIcon}>
                      <img src={partner.icon} alt="" />
                    </div>
                    <div className={styles.boostsInformation}>
                      <div className={styles.boostsItemTitle}>{partner.name}</div>
                      <div className={styles.boostsItemPrice}>
                        <span>+{partner.price}</span>
                        <img src={require("../components/images/energy-active.svg").default} alt="" />
                      </div>
                    </div>
                    <div className={styles.boostsArrow}>
                      <img src={require("./images/alt-arrow-right-line-duotone.svg").default} alt="" />
                    </div>
                  </div>
                ))} */}

              </div>
            </div>
          </Modal>}
        {modal === 'task' &&
          <Modal onClose={() => handleModaleClick("tasks")}>
            <div className={styles.boostIcon}>
              <img src={require("../components/images/referral.svg").default} alt="" />
            </div>
            <div className={styles.boostTitle}>
              Invite a friend
            </div>
            <div className={styles.taskDescription}>
              Share the referral link with your friends and get energy as a reward for each friend who has played 5 games.
            </div>
            <div className={styles.boostPriceLevel}>
              <img src={require("../components/images/energy-active.svg").default} alt="" />
              <b>+15</b>
            </div>
            <div className={styles.taskList}>
              <div className={styles.input} onClick={() => handleCopy(account.referal_code)}>
                <div>
                  <span>Invite code</span>
                  <span>{account?.referal_code}</span>{/* <span>{account?.referal_code.toString().slice(0, 3)} {account?.referal_code.toString().slice(3, 6)}</span> */}
                </div>
                <div>
                  <img src={require("./images/copy-line-duotone.svg").default} alt="" />
                </div>
              </div>
              <ButtonHOH {...{ playSound }} text="Share" onClick={() => navigate("/share")} />
            </div>


            {/* <div className={styles.boostIcon}>
              <img src={require("../components/images/HAMC.svg").default} alt="" />
            </div>
            <div className={styles.boostTitle}>
              {partners.filter((partner) => partner._id === selectedPartnerId)[0].name}
            </div>
            <div className={styles.taskDescription}>
              {partners.filter((partner) => partner._id === selectedPartnerId)[0].description}
            </div>
            <div className={styles.boostPriceLevel}>
              <b>+{partners.filter((partner) => partner._id === selectedPartnerId)[0].price}</b>
              <img src={require("../components/images/energy-active.svg").default} alt="" />
            </div>
            <div className={styles.taskList}>
              {tasks.filter((task) => task.partner_id === selectedPartnerId).map((task) => (
                task.upload ?
                  <div className={styles.taskItem} key={task._id}>
                    <div>
                      <div className={styles.taskInformation}>
                        <div className={styles.taskItemTitle}>{task.name}</div>
                        <div className={styles.taskItemDescription}>{task.description}</div>
                      </div>
                    </div>
                    <ButtonHOH text="Upload" reverse={true} />
                  </div>
                  :
                  <Link to={task.link}>
                    <div className={styles.taskItem} key={task._id}>
                      <div>
                        <div className={styles.taskIcon}>
                          <img src={require("./images/alt-arrow-right-line-duotone.svg").default} alt="" />
                        </div>
                        <div className={styles.taskInformation}>
                          <div className={styles.taskItemTitle}>{task.name}</div>
                          <div className={styles.taskItemDescription}>{task.description}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
              ))}
            </div> */}
          </Modal>}
        {modal === 'leaders' &&
          <Modal onClose={() => handleModaleClick(null)}>
            <div className={styles.leaders}>
              <div className={styles.leadersTitle}>
                <img src={require("../components/images/cup-star-line-duotone.svg").default} alt="" />
                <span>Leaderboard</span>
              </div>
              <div className={styles.leadersList}>
                {leaders?.list?.map((leader, index) => (
                  <div className={styles.leader} key={leader._id}>
                    <div className={styles.leaderPlace}>{index + 1}</div>
                    <div className={styles.leaderUserData}>
                      <div className={styles.leaderUsername}>{leader.username}</div>
                      <div className={styles.leaderBalance}>
                        <div className={styles.balanceInfo}>
                          <img src={require("../components/images/HAMC.svg").default} alt="" />
                          <span>{leader.game_balance}</span>
                        </div>
                        <div className={styles.balanceInfo}>
                          <img src={require("../components/images/HAMC2.svg").default} alt="" />
                          <span>{leader.balance}</span>
                        </div>
                        <div className={styles.balanceInfo}>
                          <img src={require("../components/images/referral.svg").default} alt="" />
                          <span>{leader.referrals}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.leaderHamster}>
                      <img src={`./${leader.avatar}`} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.userPlace}>
              {account && leaders &&
                <div className={styles.leader} >
                  <div className={styles.leaderPlace}>{leaders?.my_info?.place}</div>
                  <div className={styles.leaderUserData}>
                    <div className={styles.leaderUsername}>{account.username}</div>
                    <div className={styles.leaderBalance}>
                      <div className={styles.balanceInfo}>
                        <img src={require("../components/images/HAMC.svg").default} alt="" />
                        <span>{account.game_balance}</span>
                      </div>
                      <div className={styles.balanceInfo}>
                        <img src={require("../components/images/HAMC2.svg").default} alt="" />
                        <span>{account.balance}</span>
                      </div>
                      <div className={styles.balanceInfo}>
                        <img src={require("../components/images/referral.svg").default} alt="" />
                        <span>{leaders?.my_info?.referrals}</span>
                      </div>
                    </div>
                  </div>
                  {
                    account ?
                      <div className={styles.leaderHamster}>
                        <img src={account.avatar ? account.avatar : require("./images/hamster.png").default} />
                      </div>
                      : <LoadingCircle />
                  }

                </div>
              }
            </div>
          </Modal>}

        <MenuBar {...{ playSound }} />
      </div>
    </>
  );
}

export default Game;
